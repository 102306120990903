<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        The following titration curve is the kind of curve expected for the titration of a
        ___________ acid with a ___________ base.
      </p>

      <p class="pl-6 mb-3">
        <v-img :src="imageName" style="max-width: 391px" :alt="imageText" />
      </p>

      <v-radio-group v-model="inputs.input1">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.text"
          class="py-0 my-0"
          :disabled="!allowEditing"
        />
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'OleMiss_UnknownAcidW3_PostlabQ5',
  components: {
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          text: 'strong, strong',
          value: 'SS',
        },
        {
          text: 'strong, weak',
          value: 'SW',
        },
        {
          text: 'weak, strong',
          value: 'WS',
        },
        {
          text: 'weak, weak',
          value: 'WW',
        },
        {
          text: 'None of the above',
          value: 'NONE',
        },
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content ?? 2;
    },
    imageName() {
      if (this.versionNumber.value === 1) {
        return '/img/assignments/titrationCurve_Type2.png';
      } else if (this.versionNumber.value === 2) {
        return '/img/assignments/titrationCurve_Type6.png';
      } else {
        return '';
      }
    },
    imageText() {
      if (this.versionNumber.value === 1) {
        // weak strong
        return 'A graph of pH vs Volume of alkali added with the initial pH around 3 and the equivalence point occurring at a pH around 9';
      } else if (this.versionNumber.value === 2) {
        // strong strong
        return 'A graph of pH vs Volume of alkali added with the initial pH around 1 and the equivalence point occurring at a pH of 7';
      } else {
        return '';
      }
    },
  },
};
</script>
